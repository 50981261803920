import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import richText from '../serializers/richText'
import ProjectList from '../components/ProjectList'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import Link from '../components/Link'

// import Seo from '../components/Seo';

export default ({location, pageContext}) => {
  const {
    seo = {},
    description,
    secondaryDescription,
    projectList,
    servicesList,
    collaboratorsList,
    footer,
  } = pageContext
  const {title, projects} = projectList
  const {copy} = footer

  const metaTitle = seo.metaTitle || 'Katherine Pihl'
  const openGraphTitle = seo.openGraphTitle || 'Katherine Pihl'
  const twitterTitle = seo.twitterTitle || 'Katherine Pihl'

  return (
    <React.Fragment>
      <Seo
        metaTitle={metaTitle}
        metaDescription={seo.metaDescription}
        metaKeywords={seo.metaKeywords}
        openGraphTitle={openGraphTitle}
        openGraphDescription={seo.openGraphDescription}
        openGraphImage={seo.openGraphImage}
        twitterTitle={twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        pathname={location.pathname}
      />
      <div className="pt11--md pt8">
        <div className="grid-container contained">
          <div className="row">
            <div className="col c9--lg c10--md mb14">
              <div className="rich-text-hero">
                <BlockContent blocks={description} serializers={richText} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProjectList title={title} projects={projects} />
      <div className="mt15">
        <div className="grid-container contained">
          <div className="row">
            <div className="col c12 c9--md">
              <div className="rich-text-hero mb8--md mb5">
                <BlockContent
                  blocks={secondaryDescription}
                  serializers={richText}
                />
              </div>
              <div className="">
                <div className="hide--md mb4 col">
                  <h3 className="sans--xs uppercase fw--500 mb2">
                    {servicesList.title}
                  </h3>
                  {servicesList.services.map(service => (
                    <p className="sans--sm sans--md--md mb1">{service}</p>
                  ))}
                </div>
                <div className="hide--md mb4 col">
                  <h3 className="sans--xs uppercase fw--500 mb2">
                    {collaboratorsList.title}
                  </h3>
                  {collaboratorsList.collaborators.map(({name, url}) => (
                    <Link
                      openInNewWindow
                      className="sans--sm sans--md--md mb1 db link--opacity no-focus"
                      url={url}
                    >
                      {name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb8">
            <div className="col c3--md show--md">
              <h3 className="sans--xs uppercase fw--500 mb2">
                {servicesList.title}
              </h3>
              {servicesList.services.map(service => (
                <p className="sans--sm sans--md--md mb1">{service}</p>
              ))}
            </div>
            <div className="col c3--md show--md">
              <h3 className="sans--xs uppercase fw--500 mb2">
                {collaboratorsList.title}
              </h3>
              {collaboratorsList.collaborators.map(({name, url}) => (
                <Link
                  className="sans--sm sans--md--md mb1 db link--opacity no-focus"
                  url={url}
                  openInNewWindow
                >
                  {name}
                </Link>
              ))}
            </div>
          </div>
          <Footer copy={copy} />
        </div>
      </div>
    </React.Fragment>
  )
}
